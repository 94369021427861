import DashBoard from "./dashboard/dashboard.js";
import Login from "./login/login";
import ResetPage from "./login/captcha";
import Register from "./registration/register";
import User from "./users/users";
import ChangePassword from "./users/changepassword";

import React, { useState } from "react";
import { BrowserRouter, Switch, Route} from "react-router-dom";

const Home = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`/`} exact component={DashBoard} />
        <Route path={'/emailconfirm'} exact component={ResetPage} />

        <Route path={'/reset'} exact component={ChangePassword} />
        <Route path={`/login`} exact component={Login} />
        <Route path={`/register`} exact component={Register} />
        {/* <Route path={`/admin`} exact component={AdminDashBoard} /> */}
        <Route path={`/user`} exact component={User} />
      </Switch>
    </BrowserRouter>
  );
};

export default Home;
