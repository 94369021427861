import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import { DataGrid } from "@material-ui/data-grid";
import "./admindashboard.scss";
import URL from "../url";
import produce from "immer"
import { string } from "yup";
import deepClone from "deep-clone";

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { field: "id", headerName: "ID", width: 100 },
        { field: "username", headerName: "Username", width: 150 },
        { field: "email", headerName: "Email", width: 300 },
        {
          field: "role",
          headerName: "Role",
          width: 150,
        },
      ],
      rows: [
        { id: 1, username: "Snow", email: "johndoe@gmail.com", role: "Admin" },
        {
          id: 2,
          username: "Lannister",
          email: "johndoe@gmail.com",
          role: "Editor",
        },
        {
          id: 3,
          username: "Lannister",
          email: "johndoe@gmail.com",
          role: "Editor",
        },
        {
          id: 4,
          username: "Stark",
          email: "johndoe@gmail.com",
          role: "Editor",
        },
        {
          id: 5,
          username: "Targaryen",
          email: "johndoe@gmail.com",
          role: "Editor",
        },
        {
          id: 6,
          username: "Melisandre",
          email: "johndoe@gmail.com",
          role: "Commentor",
        },
        {
          id: 7,
          username: "Clifford",
          email: "johndoe@gmail.com",
          role: "Commentor",
        },
        {
          id: 8,
          username: "Frances",
          email: "johndoe@gmail.com",
          role: "Commentor",
        },
        {
          id: 9,
          username: "Roxie",
          email: "johndoe@gmail.com",
          role: "Commentor",
        },
        {
          id: 9,
          username: "Grey",
          email: "johndoe@gmail.com",
          role: "Commentor",
        },
      ],
      linegraph: {
        series: [
          {
            name: "Users",
            data: [],
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "Total Visits by month",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
            ],
          },
        },
      },
      bargraph: {
        series: [
          {
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: "Total Visits by Locations",
            align: "left",
          },
          xaxis: {
            categories: [
              "South Korea",
              "Canada",
              "United Kingdom",
              "Netherlands",
              "Italy",
              "France",
              "Japan",
              "United States",
              "China",
              "Germany",
            ],
          },
        },
      },
      treechart: {
        series: [
          {
            data: [
              {
                x: "English Proofreading",
                y: 218,
              },
              {
                x: "Quality Assurance",
                y: 149,
              },
              {
                x: "FAQs",
                y: 184,
              },
              {
                x: "Translation Service",
                y: 55,
              },
              {
                x: "Comparision",
                y: 84,
              },
              {
                x: "Subjects",
                y: 31,
              },
            ],
          },
        ],
        options: {
          legend: {
            show: false,
          },
          chart: {
            height: 350,
            type: "treemap",
          },
          title: {
            text: "Visits Per page",
          },
        },
      },
      messages: [],
      linegraphData: [],
      bargraphData: [],
      treechartData: []
    };
  }

  componentDidMount() {

    this.fetching();
  }

  fetching = () => {
    fetch(URL("gaReports"))
      .then((res) => res.json())
      .then((result) => {
        if(result && result.data){
          const clonedState = deepClone(this.state);
          const country = result.data.visits_by_country.map(x => x.country)
          clonedState.bargraph.options.xaxis.categories = country
          const VisitsByCountry = result.data.visits_by_country.map(y => y.visits)
          clonedState.bargraph.series[0].data = VisitsByCountry
          const month = result.data.visits_per_month.map(x => x.month)
          clonedState.linegraph.options.xaxis.categories = month
          const VisitsByMonth = result.data.visits_per_month.map(y => y.visits)
          clonedState.linegraph.series[0].data = VisitsByMonth
          const anArray = [];
          Object.keys(result.data.visits_per_page).forEach(string => anArray.push({ x: string, y: result.data?.visits_per_page[string] }));
          clonedState.treechart.series[0].data = anArray
          this.setState(clonedState);
        }

      })
    // fetch(URL("contact"))
    //   .then((res) => res.json())
    //   .then((result) => this.setState({ ...this.state, messages: result }));
    // fetch(URL("gaReports"))
    //   .then((res) => res.json())
    //   .then((result) => {
    //     //// debugger
    //     this.setState({ ...this.state, linegraphData: result[0].data.visits_per_month })
    //     this.setState({ ...this.state, bargraphData: result[0].data.visits_by_country })
    //   });
    // fetch("https://run.mocky.io/v3/9daf7435-bbf2-494a-b018-fffe2722bc78")
    //   .then((res) => res.json())
    //   .then((result) => {

    //     /* const nextState = produce(this.state,draft=>{
    //       draft[1].done = true
    //       draft.push({treechart: {series: {data:anArray}}});
    //     })
    //     this.setState({ ...this.state, nextState }) */
    //     //// debugger
    //   });
  };
  render() {
    if (!this.state) return null
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="card-body">
                <ReactApexChart
                  options={this.state.linegraph.options}
                  series={this.state.linegraph.series}
                  type="line"
                  height={350}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="card-body">
                <ReactApexChart
                  options={this.state.bargraph.options}
                  series={this.state.bargraph.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="card-body">
                <ReactApexChart
                  options={this.state.treechart.options}
                  series={this.state.treechart.series}
                  type="treemap"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-6 col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <h6 className="card-title">List of Users</h6>
                </div>
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={this.state.rows}
                    columns={this.state.columns}
                    pageSize={5}
                    checkboxSelection
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Recent Messages</h6>
                <div className="mt-4">
                  {this.state.messages.slice(0, 4).map((item, idx) => (
                    <div className="message">
                      <div className="d-flex align-items-start border-left-line message-content pb-3">
                        <div className="icon-card">
                          <ChatBubbleOutlineOutlinedIcon />
                        </div>
                        <div className="ml-3 mt-2">
                          <h6 className="text-dark font-weight-medium mb-2">
                            {item.firstname}
                          </h6>
                          <p className="font-14 mb-2 text-muted">
                            {item?.content?.substring(0, 30)}
                          </p>
                          <span className="font-weight-light text-muted">
                            {item?.address?.substring(0, 30)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
