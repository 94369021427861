import React, { useState } from "react";
import { router } from "../contact/contact";
import Proptypes from "prop-types";
import "react-confirm-alert/src/react-confirm-alert.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useStyles } from "../MessageDetails/MessageDetails";
import axios from "axios";
import URL from "../url";
import Grid from "@material-ui/core/Grid";

const Reply = ({ setView = () => {}, messageDetail }) => {
  const [formState, setFormState] = useState({
    email: messageDetail.email,
    history: messageDetail,
  });

  const handleFormInput = (e) => {
    setFormState((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const classes = useStyles();
  const { email } = messageDetail;
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          align="left"
          style={{ textAlign: "left" }}
          className={classes.mb2}
        >
          Reply Message
        </Typography>
        <form className={classes.form} id="register-form" noValidate>
          <div>
          <Grid item md={6} sm={6} xs={6}>
            <TextField disabled = {true}
              variant="outlined"
              id="email"
              name="email"
              disabled
              value={`To: ${email}`}
              className={classes.mb2}
            />
          </Grid>
          </div>
          <div>
          <Grid item md={6} sm={6} xs={6}>
            <TextField disabled = {true}
              variant="outlined"
              id="language"
              label="Subject"
              name="subject"
              className={classes.mb2}
              onChange={handleFormInput}
            />
          </Grid>
          </div>
          <div>
          <Grid item md={6} sm={6} xs={6}>
            <TextField disabled = {true}
              variant="outlined"
              id="message"
              label="Message"
              name="message"
              className={classes.mb2}
              multiline={true}
              rows={5}
              onChange={handleFormInput}
            />
          </Grid>
          </div>

          <Button
            style={{
              marginTop: 20,
              color: "white",
              backgroundColor: "#3AB8A1",
            }}
            variant="contained"
            onClick={() => setView(router.userTable)}
          >
            Back
          </Button>
          <Button
            style={{
              marginTop: 20,
              marginLeft: 20,
              color: "white",
              backgroundColor: "#3AB8A1",
            }}
            variant="contained"
            onClick={() => {
              console.log(formState);
              axios
                .post(URL("reply_email"), formState)
                .then((res) => {
                  setView(router.userTable);
                })
                .catch((err) => {
                  alert(err.message);
                });
            }}
          >
            Send
          </Button>
        </form>
      </div>
    </Container>
  );
};

Reply.propTypes = {
  setView: Proptypes.func.isRequired,
};

export default Reply;
