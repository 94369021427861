import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as ReactBootStrap from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import URL from "../url";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        Edfluent
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#1DB195",
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  let token = window.location.href.split("=")[1];
  let history = useHistory();


  const [loginError,setLoginError] = React.useState(null);
  const [isLoading,setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState(null);
  const [password2, setPassword2] = React.useState(null);
  const [passwordStrength, setPasswordStrength] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [matcherror, setmatchError] = React.useState(null);

  const [status, setStatus] = React.useState("");


  const measureStrength = (password) => {
    let score = 0;
    let regexPositive = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
    if (password.length >= 8)
      regexPositive.forEach((regex, index) => {
        if (new RegExp(regex).test(password)) {
          score += 1;
        }
      });
    switch (score) {
      case 0:
      case 1:
        setPasswordStrength("weak");
        break;
      case 2:
      case 3:
        setPasswordStrength("good");

        break;
      case 4:
      case 5:
        setPasswordStrength("strong");
        break;
    }
    setPassword(password);
  };

  const validatePassword = (e) => {
    let password = e.target.value;
    let capsCount, smallCount, numberCount, symbolCount;
    if (password.length == "") {
      setErrorMessage("Required ");
      measureStrength(password);
    } else if (password.length < 8) {
      setErrorMessage("Must be at least 8 characters long ");
      measureStrength(password);
    } else {
      capsCount = (password.match(/[A-Z]/g) || []).length;
      smallCount = (password.match(/[a-z]/g) || []).length;
      numberCount = (password.match(/[0-9]/g) || []).length;
      symbolCount = (password.match(/\W/g) || []).length;
      if (capsCount < 1) {
        setErrorMessage("Must contain an uppercase letter");
      } else if (smallCount < 1) {
        setErrorMessage("Must contain a lowercase letter");
      } else if (numberCount < 1) {
        setErrorMessage("Must contain a number");
      } else if (symbolCount < 1) {
        setErrorMessage("Must contain a symbol");
      } else {
        setErrorMessage(null);
      }
      measureStrength(password);
    }
  };

  const handleChange = (e) => {
    validatePassword(e);
    setPassword(e.target.value);
  };

  const checkvalidity = (e) => {
    if (e.target.value !== password) {
      setmatchError("Password didn't match");
    } else {
      setmatchError(null);
    }
  };

  const handlesubmit = async() => {
    setIsLoading(true)
    if(matcherror==null){
      await axios.post(URL("reset_password"),
      {
        resetToken:token,
        newPassword: password,
      })
      .then((res)=>{
        if(res.status==200){
          if(res.data?.error){
            setStatus(res.data?.error)
            setIsLoading(false)
          }
          else{
            toast.success("Password Changed Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIsLoading(false)
            history.push("/login")
          }
        }
        else{
          toast.success("Invalid Inputs", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false)
        }
      })}
    
    else{
      toast.success("Invalid Inputs", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setIsLoading(false)
    
  }
  
  
  return (
    <div className="mt-4 container-fluid">
    
     <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar src="" className={classes.avatar}></Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={password}
                      onChange={handleChange}
                      error={errorMessage}
                      helperText={errorMessage}
                    />
                    {passwordStrength === "strong" ? (
                      <div className="ml-3" style={{ color: "green" }}>
                        {passwordStrength}
                      </div>
                    ) : passwordStrength === "weak" ? (
                      <div className="ml-3" style={{ color: "red" }}>
                        {passwordStrength}
                      </div>
                    ) : (
                      <div className="ml-3" style={{ color: "#a86f32" }}>
                        {passwordStrength}
                      </div>
                    )}

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password2"
                      label="Confirm Password"
                      type="password"
                      id="password2"
                      value={password2}
                      onChange={checkvalidity}
                      error={matcherror}
                      helperText={matcherror}
                    />
                
          {isLoading?(<div style={{textAlign:"center"}}><ReactBootStrap.Spinner animation="border" variant="success" /></div>):""}
          {status === "" ? (
                ""
              ) : (
                <Box>
                  <p className="text-center text-danger mt-3 mb-0">{status}</p>
                </Box>
              )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={handlesubmit}
          >
            Change Password
          </Button>
          
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
   </div>
  );
};

export default ChangePassword;
