import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./register.scss";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import RegistrationForm from "./registrationform"
import URL from "../url";
import Toast from "../toasting";
import {measureStrength, ValidationSchema} from "../validation";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  error: {
    color: "#c62121",
    padding: "10px",
    backgroundColor: "#ffe5e7",
    border: "1px solid #f5c6cb",
    width: "100%",
    borderRadius: "5px",
    marginTop: "15px",
  },
  errorSvg: {
    fontSize: "1rem",
    marginRight: "7px",
  },
  errorSpan: {
    fontSize: "14px",
  },
}));
const PrimaryButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#1db195",
    padding: "8px 20px",
    "&:hover": {
      backgroundColor: "#178a74",
    },
  },
}))(Button);

export default function Register({ setUserDetail, userDetail, setNavCase }) {
  const classes = useStyles();

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("Required");
  const [password2, setPassword2] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const [passwordStrength, setPasswordStrength] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [matcherror, setmatchError] = React.useState(null);

  const [registrationError, setRegistrationError] = React.useState(null);
  const [emailExists, setEmailExists] = React.useState(null);

  const [languages, setLanguage] = React.useState([]);

  useEffect(() => {
    fetch(URL("language"))
      .then((res) => res.json())
      .then((result) => setLanguage(result.reverse()));
  }, []);


  const formik = useFormik({
    initialValues: {
      Email: userDetail?.email ? userDetail.email : "",
      Firstname: userDetail?.firstname ? userDetail.firstname : "",
      Lastname: userDetail?.lastname ? userDetail.lastname : "",
      Username: userDetail?.username ? userDetail.username : "",
      Contact: userDetail?.contact ? userDetail.contact : "",
      Date: userDetail?.date ? userDetail.date : "",
      Role: userDetail?.role ? userDetail?.role : "",
      Address: userDetail?.address ? userDetail.address : "",
      Gender: userDetail?.gender ? userDetail.gender : "",
      Confirmpassword: userDetail ? "Admin!123" : "",
      Userid: userDetail ? userDetail._id : null,
      Content: userDetail?.content ? userDetail.content : "",
      Language: userDetail?.language? userDetail.language: "",
      Password: userDetail? "Admin!123":"",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      if (userDetail?.role) {
        setIsLoading(true);
        await axios
          .post(URL("user/update"), {
            id: values.Userid,
            username: values.Username,
            firstname: values.Firstname,
            lastname: values.Lastname,
            address: values.Address,
            contact: values.Contact,
            date: values.Date,
            content: values.Content,
            email: values.Email,
            gender: values.Gender,
            role: values.Role,
            language: values.Language,
          })
          .then((res) => {
            /*// debugger;*/
            if (res.status === 200) {
              Toast(values.Username+" is updated successfully")
              setIsLoading(false);
              setNavCase("users");
            } else {
              setRegistrationError(res.data.error);
              setIsLoading(false);
            }
          });
      } else{
        setIsLoading(true);
        try {
          axios
            .post(URL("register"), {
              username: values.Username,
              firstname: values.Firstname,
              lastname: values.Lastname,
              address: values.Address,
              contact: values.Contact,
              date: values.Date,
              content: values.Content,
              email: values.Email,
              gender: values.Gender,
              role: values.Role,
              password: values.Password,
              language: values.Language,
            })
            .then((res) => {
              /*// debugger */;
              if (res.status === 201) {
                Toast(values.Username+" is created successfully")
                setNavCase("users");
                setIsLoading(false);
              } else {
                setIsLoading(false);
                if (res.data.message === "Email is already registered") {
                  setEmailExists("Email is already registered");
                }
                setRegistrationError(res.data.message);
              }
            })
            .catch((e) => {
              /*// debugger */;
              setIsLoading(false);
              setRegistrationError(e.response.data.message);
            });
        } catch (e) {
          /*// debugger */;
        }
      }
    },
  });

  
  const handleChange = (e) => {
    formik.handleChange(e)
    measureStrength(e,setPasswordStrength)
  };


  return (
    <RegistrationForm
      formik={formik}
      classes={classes}
      isLoading={isLoading}
      setUserDetail={setUserDetail}
      userDetail={userDetail}
      languages={languages}
      password={password}
      handleChange={handleChange}
      errorMessage={errorMessage}
      passwordStrength={passwordStrength}
      matcherror={matcherror}
      registrationError={registrationError}
      setNavCase={setNavCase}
      materialDateInput={materialDateInput}
      password2={password2}
      PrimaryButton={PrimaryButton}
      passwordError={passwordError}
    />
  );
}
