import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));
const PrimaryButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#1db195",
    padding: "8px 20px",
    "&:hover": {
      backgroundColor: "#178a74",
    },
  },
}))(Button);

export default function ViewUser({id,setUserdetail, userDetail, setNavCase}) {
  const classes = useStyles();
  let history = useHistory();

  const setRegister = (user)=> {
    setNavCase("register");
    setUserdetail(userDetail);
  };
  // const [firstname, setFirstname] = useState("");
  // const [date, setDate] = useState();
  // const [lastname, setLastname] = useState("");
  // const [gender, setGender] = useState("");
  // const [contact, setContact] = useState("");
  // const [content, setContent] = useState("");
  // const [username, setusername] = useState("");
  // const [email, setemail] = useState("");
  // const [role, setRole] = useState("");
  // const [address, setAddress] = useState("");
  // const[userid, setUserid] = useState("")



  // useEffect(() => {
  //     setFirstname(userDetail?.firstname);
  //     setemail(userDetail?.email)
  //     setLastname(userDetail?.lastname);
  //     setGender(userDetail?.gender);
  //     setAddress(userDetail?.address);
  //     setRole(userDetail?.role?.role);
  //     setusername(userDetail?.username);
  //     setContent(userDetail?.content);
  //     setDate(userDetail?.date);
  //     setContact(userDetail?.contact);
  //     setUserid(userDetail?._id)
  //   }
  // , [])

  const userlist = () => {

      setNavCase("users")
  }



  return (
    <Container component="main" maxWidth="lg">
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <CssBaseline />
      <div className={classes.paper}>
        <div style={{display:"flex"}}>
        <div>

        <Typography component="h1" variant="h5" align="left">
          User Details
        </Typography>
        </div>

        <div style={{position:"absolute", right:"20%"}}>
          <button
            type="button"
            class="btn float-right"
            style={{
              backgroundColor: "#3AB8A1",
              color: "white",
              padding: "8px 20px",
            }}
            onClick={() => setNavCase("register")}
          >
            Add User
          </button>
          </div>
        </div>
        <form className={classes.form} id="register-form" noValidate>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstname"
                label="Firstname"
                name="firstname"
                autoComplete="firstname"
                autoFocus
                value={userDetail?.firstname}
                disabled
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastname"
                label="Lastname"
                name="lastname"
                autoComplete="lastname"
                autoFocus
                value={userDetail?.lastname}
                disabled
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={userDetail?.username}
                disabled
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="contact"
                label="Contact No."
                name="contact"
                autoComplete="contact"
                autoFocus
                value={userDetail?.contact}
                disabled
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={userDetail?.email}
                disabled
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
            <TextField
               variant="outlined"
               margin="normal"
               required
               fullWidth
               id="lastname"
               label="Language"
               name="lastname"
               autoComplete="lastname"
               autoFocus
               value={userDetail?.language}
               disabled
             />
            </Grid>
            
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                select
                label="Select Role"
                value={userDetail?.role}
                variant="outlined"
                className="w-100 mt-3"
                disabled
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Editor">Editor</MenuItem>
                <MenuItem value="Commentor">Commentor</MenuItem>
              </TextField>
              {/* <FormControl variant="filled" style={{ width: "100%" }}>
                <InputLabel
                  htmlFor="filled-age-native-simple"
                  style={{ fontSize: "20px" }}
                >
                  Select role
                </InputLabel>
                <Select
                  native
                  value={role}
                  onChange={handlerole}
                  inputProps={{
                    name: "Role",
                    id: "filled-age-native-simple",
                  }}
                >
                  <option>Admin</option>
                  <option>Editor</option>
                  <option>Commentor</option>
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="address"
                autoFocus
                value={userDetail?.address}
                disabled
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                id="gender"
                label="gender"
                type="gender"
                name="gender"
                defaultValue={userDetail?.gender}
                value={userDetail?.gender}
                className="w-100 mt-3"
                disabled
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                id="date"
                label=""
                type="date"
                name="date"
                defaultValue={userDetail?.date}
                value={userDetail?.date}
                className="w-100 mt-3"
                disabled
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                name="content"
                label="Content"
                className="w-100"
                name="content"
                multiline={true}
                rows={5}
                variant="outlined"
                value={userDetail?.content}
                disabled
              />
            </Grid>
          </Grid>

          <PrimaryButton
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>{
              setRegister(userDetail)
            }}
          >
            Update User
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            style = {{marginLeft:"5px"}}
            onClick={userlist}
          >
           Back
          </PrimaryButton>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
