import React, { Component } from 'react';
import './App.css';
import Home from "./components/home"
class App extends Component {
  render() {
    return (
      <Home />
    );
  }
}
export default App;