import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as ReactBootStrap from "react-bootstrap";
import CancelIcon from "@material-ui/icons/Cancel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import "react-confirm-alert/src/react-confirm-alert.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Edfluent
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const RegistrationForm = ({
  isLoading,
  formik,
  classes,
  userDetail,
  setUserDetail,
  languages = [ "Japanese", "English"], //default value is provided here
  password,
  handleChange,
  errorMessage,
  passwordStrength,
  matcherror,
  registrationError,
  setNavCase,
  checkvalidity,
  password2,
  materialDateInput,
  PrimaryButton,
}) => {
  const defaultValues = (title) => {
    return {
      variant: "outlined",
      margin: "normal",
      fullWidth: true,
      id: title,
      label: title,
      name: title,
    };
  };

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" align="left">
            {userDetail ? "Update User" : "Create a User"}
          </Typography>
          {userDetail ? (
            <>
              <div style={{marginLeft:"auto", marginButtom:"20px"}}>
          {/* <button
            type="reset"
            class="btn float-right"
            style={{
              backgroundColor: "#3AB8A1",
              color: "white",
              padding: "8px 20px",
            }}
            onClick={()=>{
              console.log("done")
              setUserDetail(null)
              formik.setValues({
                Email:"",
                Firstname:"",
                Lastname:"",
                Username:"",
                Contact:"",
                Date: "",
                Role:"",
                Address:  "",
                Gender:"",
                Confirmpassword:"",
                Userid:"",
                Content:"",
                Language:"",
                Password:"",
              })
            }}

          >
            Add User
          </button> */}
          </div>
            <div
              className="alert alert-warning container text-center"
              role="alert"
              style={{ marginBottom: "0px", marginTop: "10px" }}
            >
              Note:- some changes are only applicable in your next login session
              </div>
              </>
          ) : (
            ""
          )}
          
          <form
            className={classes.form}
            id="register-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  {...defaultValues("Firstname")}
                  {...formik.getFieldProps("Firstname")}
                  required
                  error={
                    formik.errors.Firstname && formik.touched.Firstname
                      ? formik.errors.Firstname
                      : null
                  }
                  helperText={
                    formik.errors.Firstname && formik.touched.Firstname
                      ? formik.errors.Firstname
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  {...defaultValues("Lastname")}
                  {...formik.getFieldProps("Lastname")}
                  required
                  error={
                    formik.errors.Lastname && formik.touched.Lastname
                      ? formik.errors.Lastname
                      : null
                  }
                  helperText={
                    formik.errors.Lastname && formik.touched.Lastname
                      ? formik.errors.Lastname
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  {...defaultValues("Username")}
                  {...formik.getFieldProps("Username")}
                  required
                  error={
                    formik.errors.Username && formik.touched.Username
                      ? formik.errors.Username
                      : null
                  }
                  helperText={
                    formik.errors.Username && formik.touched.Username
                      ? formik.errors.Username
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  {...defaultValues("Contact")}
                  type="tel"
                  {...formik.getFieldProps("Contact")}
                  error={
                    formik.errors.Contact && formik.touched.Contact
                      ? formik.errors.Contact
                      : null
                  }
                  helperText={
                    formik.errors.Contact && formik.touched.Contact
                      ? formik.errors.Contact
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  {...defaultValues("Email")}
                  error={
                    formik.errors.Email && formik.touched.Email ? (
                      <div>{formik.errors.Email}</div>
                    ) : null
                  }
                  required
                  helperText={
                    formik.errors.Email && formik.touched.Email ? (
                      <div>{formik.errors.Email}</div>
                    ) : null
                  }
                  autoComplete="Email"
                  {...formik.getFieldProps("Email")}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  select
                  label="Language"
                  {...formik.getFieldProps("Language")}
                  variant="outlined"
                  required
                  className="w-100 mt-3"
                  error={
                    formik.errors.Language && formik.touched.Language ? (
                      <div>{formik.errors.Language}</div>
                    ) : null
                  }
                  helperText={
                    formik.errors.Language && formik.touched.Language ? (
                      <div>{formik.errors.Language}</div>
                    ) : null
                  }
                >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Japanese">Japanese</MenuItem>

                  {languages?.map((lan, idx) => (
                    <MenuItem value={lan.name}>{lan.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              {userDetail ? (
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField
                        {...defaultValues("Password")}
                        type="password"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField
                        {...defaultValues("Confirm Password")}
                        type="password"
                        id="password2"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField
                        {...defaultValues("Password")}
                        type="password"
                        required
                        // {...formik.getFieldProps("Password")}
                        value={formik.values.Password}
                        onChange={handleChange}
                        error={
                          errorMessage ||
                          (formik.errors.Password && formik.touched.Password
                            ? formik.errors.Password
                            : null)
                        }
                        helperText={
                          errorMessage ||
                          (formik.errors.Password && formik.touched.Password
                            ? formik.errors.Password
                            : null)
                        }
                      />
                      {passwordStrength === "strong" ? (
                        <div className="ml-3" style={{ color: "green" }}>
                          {passwordStrength}
                        </div>
                      ) : passwordStrength === "weak" ? (
                        <div className="ml-3" style={{ color: "red" }}>
                          {passwordStrength}
                        </div>
                      ) : (
                        <div className="ml-3" style={{ color: "#a86f32" }}>
                          {passwordStrength}
                        </div>
                      )}
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                      <TextField
                        {...defaultValues("Confirm Password")}
                        type="password"
                        {...formik.getFieldProps("Confirmpassword")}
                        variant="outlined"
                        className="w-100 mt-3"
                        required
                        error={
                          formik.errors.Confirmpassword &&
                          formik.touched.Confirmpassword ? (
                            <div>{formik.errors.Confirmpassword}</div>
                          ) : null
                        }
                        helperText={
                          formik.errors.Confirmpassword &&
                          formik.touched.Confirmpassword ? (
                            <div>{formik.errors.Confirmpassword}</div>
                          ) : null
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  select
                  label="Role"
                  required
                  {...formik.getFieldProps("Role")}
                  error={
                    formik.errors.Role && formik.touched.Role
                      ? formik.errors.Role
                      : null
                  }
                  helperText={
                    formik.errors.Role && formik.touched.Role
                      ? formik.errors.Role
                      : null
                  }
                  variant="outlined"
                  className="w-100 mt-3"
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Editor">Editor</MenuItem>
                  <MenuItem value="Commentor">Commentor</MenuItem>
                </TextField>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  {...defaultValues("Address")}
                  {...formik.getFieldProps("Address")}
                  error={
                    formik.errors.Address && formik.touched.Address
                      ? formik.errors.Address
                      : null
                  }
                  helperText={
                    formik.errors.Address && formik.touched.Address
                      ? formik.errors.Address
                      : null
                  }
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    aria-label="Gender"
                    name="Gender"
                    row
                    value={formik.values.Gender}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div
                  style={{
                    color: "#000000#A",
                    fontSize: "1rem",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    lineHeight: " 1",
                  }}
                >
                  Date of Birth
                </div>
                <TextField
                  variant="outlined"
                  id="date"
                  label=""
                  name="date"
                  // defaultValue={materialDateInput}
                  // required
                  type="date"
                  InputProps={{ inputProps: { max: materialDateInput } }}
                  {...formik.getFieldProps("Date")}
                  error={
                    formik.errors.Date && formik.touched.Date
                      ? formik.errors.Date
                      : null
                  }
                  helperText={
                    formik.errors.Date && formik.touched.Date
                      ? formik.errors.Date
                      : null
                  }
                  className="w-100 mt-3"
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  {...defaultValues("Content")}
                  multiline={true}
                  rows={5}
                  // defaultValue={materialDateInput}
                  {...formik.getFieldProps("Content")}
                  className="w-100"
                />
              </Grid>
            </Grid>
            <br />
            {registrationError ? (
              <Box className={classes.error}>
                <CancelIcon className={classes.errorSvg} />
                <span className={classes.errorSpan}>{registrationError}</span>
              </Box>
            ) : (
              ""
            )}
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ReactBootStrap.Spinner animation="border" variant="success" />
              </div>
            ) : (
              ""
            )}

            <PrimaryButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              // onClick={userDetail?updateUser:handleClick}
            >
              {userDetail ? "Update User" : "Create User"}
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ marginLeft: "5px" }}
              onClick={() => {
                setNavCase("users");
              }}
            >
              Back
            </PrimaryButton>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};
// RegistrationForm.propTypes = {
//   isLoading: Proptypes.bool.isRequired,
//   contacts: Proptypes.array.isRequired,
//   viewFunction: Proptypes.func.isRequired,
//   approval: Proptypes.func.isRequired,
//   setView: Proptypes.func.isRequired,
// };

export default RegistrationForm;
