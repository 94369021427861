import React, { useState, useEffect } from "react";
import Reply from "../Reply/Reply";
import deepCLone from "deep-clone";
import UserTable from "../userTable/UserTable";
import MessageDetails from "../MessageDetails/MessageDetails";
import axios from "axios";
import URL from "../url";

export const router = Object.freeze({
  userTable: "USER_TABLE",
  messageDetails: "MESSAGE_DETAILS",
  replyPage: "REPLY_PAGE",
});

export default function ContactUs() {
  const [contacts, setContacts] = useState([]);
  const [allcontacts, setAllContacts] = useState([]);

  const [view, setView] = useState(router.userTable);
  const [isLoading, setIsLoading] = useState(false);
  const [messageDetail, setMessageDetail] = useState({});
  const [error, setError] = useState(null);

  const replyPageCaller = (m) => {
    setMessageDetail(m);
    setView(router.replyPage);
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = () => {
    setIsLoading(true);
    axios
      .get(URL("contact"))
      .then((res) => {
        setContacts(res.data);
        setAllContacts(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const approval = (index) => {
    const clonedContact = deepCLone(contacts);
    const item = clonedContact[index];
    if ("status" in item) {
      item["status"] = item["status"] === "approved" ? "pending" : "approved";
    } else {
      item["status"] = "pending";
    }

    clonedContact[index] = item;
    setContacts(clonedContact);
  };

  const viewFunction = (item) => {
    setMessageDetail(item);
    setView(router.messageDetails);
  };

 
  switch (view) {
    case router.userTable:
      return (
        <UserTable
          isLoading={isLoading}
          contacts={contacts}
          setContacts={setContacts}
          viewFunction={viewFunction}
          approval={approval}
          setView={setView}
          error={error}
          replyPageCaller={replyPageCaller}
          allcontacts={allcontacts}
        />
      );
    case router.messageDetails:
      return (
        <MessageDetails selectedMessage={messageDetail} setView={setView} />
      );
    case router.replyPage:
      return <Reply messageDetail={messageDetail} setView={setView} />;
    default:
      return null;
  }
}
