import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import Cookies from 'js-cookie';

const interceptor = axios.interceptors.response.use(
  response => response,
  err => {
    let originalRequest = err.config;
    // Reject promise if usual error
    if (err.response?.status !== 401) {
      // debugger
      return Promise.reject(err);
    }else if(err.response?.status === 401 && (err.response.data?.error?.message === "jwt must be provided" || err.response.data?.error?.message === "jwt expired"|| err.response.data?.message === "no jwt")){
      // debugger
      if(Cookies.get("sessionID")){
        axios.interceptors.response.eject(interceptor);
        return axios.post('https://cms.roshandumaru.com.np/api/refreshToken')
          .then(res=>{
            //go to the original route after refresh Token
            // debugger
            return axios(originalRequest);
            //window.location.replace('/');
            //resolve responsecsm
          })
          .catch(error=>{
            //go to this route after refresh fail
            // debugger
            axios.delete('https://cms.roshandumaru.com.np/api/logoutCheck').then(res=>{
              if(res.status===200){
                localStorage.clear();
              }
            });
            window.location.replace('/login');
            return Promise.reject(error);
          });
      }
      return Promise.reject(err);
    }
  })
  
/*   
  undefined, function axiosRetryInterceptor(err){
  if(Cookies.get("sessionID")){
    /* // debugger
    if(err.response?.status === 401 && (err.response.data?.error?.message === "jwt must be provided" || err.response.data?.error?.message === "jwt expired"|| err.response.data?.message === "no jwt")){
      // debugger
      axios.post('http://localhost:3030/api/refreshToken');
      return Promise.resolve(err);
    }/* 
    else{
      return window.location.replace('/login');
    }
  }
  return Promise.reject(err);
}) */


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
