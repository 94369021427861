import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as ReactBootStrap from "react-bootstrap";
import SearchBar from "material-ui-search-bar";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Button from "@material-ui/core/Button";
import "./users.scss";
import Pagination from "react-responsive-pagination";
import URL from "../url";
require('dotenv').config();


export default function User({ setNavCase, setUserdetail, setViewUserdetail }) {
  let history = useHistory();
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [searchedUser, setSearchedUser] = useState("");
  const [searchError, setSearchError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let totalPage = Math.floor(users.length / pageSize) + 1;
  const currentUser = localStorage.getItem("userid");

  useEffect(() => {
    fetching();
  }, []);

  const fetching = () => {
    axios
      // .get(`https://cmsapi.edfluent.app/api/users/list`)
      .get(`${process.env.REACT_APP_FETCH_API}/api/users/list`)
      .then((res) => res.data)
      .then(
        (result) =>
          setAllUsers(
            result?.reverse(),
            setUsers(result.reverse()),
            setIsLoading(true)
          )
      );
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.primary,
    },
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1),
      padding: "7px 25px",
    },
    buttonDanger: {
      margin: theme.spacing(1),
      padding: "7px 25px",
      backgroundColor: "#f15e5e",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#bd2130",
      },
    },
  }));

  const deleteuserconfirm = async (index) => {
    const userId = users[index]._id;
    var res = await axios.post(URL("user/delete"), {
      id: userId,
    });
    if (res.status === 200) {
      fetching();
      toast.error("User Deleted", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const userid = localStorage.getItem("userid");
  const deleteuser = (index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div className="close-icon">
              <CloseOutlinedIcon
                onClick={() => {
                  setNavCase("users");
                  onClose();
                }}
              />
            </div>
            <div className="clearfix"></div>
            <div className="alert-content">
              <div className="icon-box">
                <CloseOutlinedIcon />
              </div>

              <h4>Are you sure?</h4>
              <p>
                Do you really want to delete this user? This process cannot be
                undone.
              </p>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setNavCase("users");
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.buttonDanger}
                onClick={() => {
                  deleteuserconfirm(index);
                  onClose();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  const setRegister = (id) => {
    setNavCase("register");
    const user = users[id];
    setUserdetail(user);
    setViewUserdetail("");
  };

  const userView = (id) => {
    setNavCase("view");
    const user = users[id];
    setViewUserdetail(user);
    setUserdetail("");
  };

  var result = [];
  const searchItem = (value) => {
    allUsers.forEach((item, index) => {
      {
        item.username.toLowerCase().startsWith(value.toLowerCase())
          ? result.push(item)
          : console.log("");
      }
    });
    // let search  = users.filter(user => user.username===value);
    if (result.length != 0) {
      setUsers(result);
      setSearchError(false);
      result = [];
    } else {
      // setUsers(allUsers);
      setSearchError(true);
      result = [];
    }
  };

  const classes = useStyles();

  const pageSzing = (e) => {
    setCurrentPage(1);
    setPageSize(e.target.value);
  };
  return (
    <div className="mt-4 container-fluid">
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <div className="row align-items-center">
        <div className="col-4">
          <h4>Users</h4>
        </div>
        <div className="col-4"></div>
        <div className="col-4">
          <button
            type="button"
            class="btn float-right"
            style={{
              backgroundColor: "#3AB8A1",
              color: "white",
              padding: "8px 20px",
            }}
            onClick={() => setRegister()}
          >
            Add User
          </button>
        </div>
      </div>
      <div className="row my-3 align-items-center">
        <div className="col-4">
          <p className="mb-0">
            Show
            <select
              className="custom-select"
              value={pageSize}
              onChange={(e) => pageSzing(e)}
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            entries
          </p>
        </div>
        <div className="col-4"></div>
        <div className="col-md-4">
          <SearchBar
            value={searchedUser}
            onChange={(value) => searchItem(value)}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="well table-responsive">
          {searchError ? (
            <h5 className="text-center" style={{ marginTop: "5%" }}>
              User not found for searched result
            </h5>
          ) : (
            <>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Full Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Language</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {users
                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((user, index) => {
                      const firstname = user.firstname ? user.firstname : "N/A";
                      const lastname = user.lastname ? user.lastname : "N/A";

                      const fullname = firstname + " " + lastname;
                      return (
                        <tr>
                          <td>{(currentPage - 1) * pageSize + index + 1}</td>
                          <td>{fullname ? fullname : "N/A"}</td>
                          <td>{user.username ? user.username : "N/A"}</td>
                          <td>{user.email ? user.email : "N/A"}</td>
                          <td>{user.language ? user.language : "N/A"}</td>
                          <td>{user?.role ? user.role : "N/A"}</td>

                          <td>
                            {/* <button type="button" class="btn btn-primary d-inline" onClick={() => {setRegister(index)}}>Edit</button>yar */}

                            <Grid item xs={8}>
                              <Tooltip title="View">
                                <IconButton aria-label="view">
                                  <VisibilityIcon
                                    onClick={() =>
                                      userView(
                                        (currentPage - 1) * pageSize + index
                                      )
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit">
                                <IconButton aria-label="edit">
                                  <EditIcon
                                    onClick={() =>
                                      setRegister(
                                        (currentPage - 1) * pageSize + index
                                      )
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              {currentUser === user._id ? (
                                ""
                              ) : (
                                <Tooltip title="Delete">
                                  <IconButton aria-label="delete">
                                    <DeleteIcon
                                      style={{ paddingTop: "0" }}
                                      onClick={() =>
                                        deleteuser(
                                          (currentPage - 1) * pageSize + index
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {totalPage > 1 ? (
                <div style={{ fontSize: "20px" }}>
                  <Pagination
                    current={currentPage > totalPage ? 1 : currentPage}
                    total={totalPage}
                    onPageChange={setCurrentPage}
                  />
                </div>
              ) : (
                <div style={{ marginBottom: "5%" }}></div>
              )}
            </>
          )}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <ReactBootStrap.Spinner animation="border" variant="success" />
        </div>
      )}
    </div>
  );
}
