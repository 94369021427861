import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "react-confirm-alert/src/react-confirm-alert.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Proptypes from "prop-types";
import { router } from "../contact/contact";
import Button from "@material-ui/core/Button";
import "./messageDetail.scss";


export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  mb2: {
    marginBottom: "30px",
    width: "100%",
  },
}));

const MessageDetails = ({ selectedMessage, setView }) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          align="left"
          style={{ textAlign: "left", marginTop: "64px" }}
        >
          Contact Details
        </Typography>
        <form className={classes.form} id="register-form" noValidate>
          <Grid
            container
            alignItems="flex-start"
            spacing={2}
            style={{ marginTop: "8px" }}
          >
            <Grid item md={6} sm={6} xs={6}>
              <TextField disabled = {true}
                variant="outlined"
                style={{color:"black"}}
                id="firstname"
                label="Full Name"
                name="fullname"
                className="w-100 mt-3"
                value={
                  selectedMessage.firstname && selectedMessage.lastname
                    ? selectedMessage.firstname + " " + selectedMessage.lastname
                    : "N/A"
                }
              />
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6} style={{ marginTop: "3%" }}>
            <TextField disabled = {true}
              variant="outlined"
              id="email"
              label="Email"
              type="email"
              name="email"
              className="w-100 mt-3"
              value={selectedMessage?.email ? selectedMessage.email : "N/A"}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={6} style={{ marginTop: "3%" }}>
            <TextField disabled = {true}
              variant="outlined"
              id="language"
              label="Language"
              type="language"
              name="language"
              className="w-100 mt-3"
              value={
                selectedMessage?.language ? selectedMessage.language : "N/A"
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={6} style={{ marginTop: "5%" }}>
            <TextField disabled = {true}
              variant="outlined"
              id="message"
              label="Message"
              type="message"
              name="message"
              className="w-100 mt-3"
              multiline={true}
              rows={5}
              value={selectedMessage?.content ? selectedMessage.content : "N/A"}
            />
          </Grid>

          <Button
            style={{ marginTop: 20, color:"white", backgroundColor:"#3AB8A1"}}
            variant="contained"
            onClick={() => setView(router.userTable)}
          >
            Back
          </Button>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
};

MessageDetails.propTypes = {
  selectedMessage: Proptypes.object.isRequired,
  setView: Proptypes.func.isRequired,
};

export default MessageDetails;
