import React from "react";
import axios from "axios";
import Toast from "../toasting";
import { ToastContainer } from "react-toastify";
import URL from "../url";

export default function PasswordResetList() {
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(URL("requests"))
      .then((res) => res.json())
      .then((result) => setUsers(result));
  };

  const handleClick = async (index) => {
    const user = users[index];
    const email = user?.email;
    await axios.post(URL("forgot_password"), { email: email }).then((res) => {
      if (res.status == 200) {
        fetchData();
        return Toast(res.data.message);
      }
    });
  };
  return (
    <div className="mt-4 container-fluid">
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <br />
      <br />

      <div>
        <h4> Reset Password Requests</h4>
      </div>
      <br />
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Emails</th>
            <th scope="col" style={{ width: "15%" }} className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, index) => (
            <tr>
              <th scope="row">{index + 1}</th>
              <td>{user.email}</td>
              <td className="text-center">
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#3dbba3" }}
                  onClick={() => handleClick(index)}
                >
                  Approve
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
