import { Grid, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as ReactBootStrap from "react-bootstrap";
import ReplyIcon from "@material-ui/icons/Reply";
import Proptypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "react-responsive-pagination";
import "./userTable.scss";
import SearchBar from "material-ui-search-bar";

const UserTable = ({
  isLoading,
  contacts,
  setContacts,
  allcontacts,
  viewFunction,
  approval,
  error,
  replyPageCaller,
}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchError, setSearchedError] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchedUser, setSearchedUser] = React.useState("");
  
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error]);

  let totalPage = Math.floor(contacts.length / pageSize) + 1;

  const pageSizing = (e) => {
    setCurrentPage(1);
    setPageSize(e.target.value);
  };

  var result = [];
  const searchItem = (value) => {
    allcontacts?.forEach((item, index) => {
      {
        const firstname = item.firstname
        ? item.firstname
        : "N/A";
      const lastname = item.lastname
        ? item.lastname
        : "N/A";
      const fullname = firstname + " " + lastname;
        fullname.toLowerCase().startsWith(value.toLowerCase())
          ? result.push(item)
          : console.log("");
      }
    });
    // let search  = users.filter(user => user.username===value);
    if (result.length != 0) {
      setContacts(result);
      setSearchedError(false);
      result = [];
    } else {
      setContacts(allcontacts)
      setSearchedError(true)
      // setUsers(allUsers);
      result = [];
    }
  };
  return (
    <div className="mt-4 container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="row align-items-center">
        <div className="col-4">
          <h4>Contacts</h4>
        </div>
        <div className="col-4"></div>
        <div className="col-4"></div>
      </div>
      <div className="row my-3 align-items-center">
        <div className="col-4">
          <p className="mb-0">
            Show
            <select className="custom-select" value={pageSize} onChange={(e)=>pageSizing(e)}>
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            entries
          </p>
        </div>
        <div className="col-4"></div>
        <div className="col-md-4">
          <SearchBar
            value={searchedUser}
            onChange={(value) => searchItem(value)}
          />
        </div>
      </div>
      {!isLoading ? (
        <div className="well">
          {searchError ? (
            <h6 className="text-center">Message not found for search result</h6>
          ) : (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>Language</th>
                  <th>Content</th>
                  <th>Actions</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {contacts
                  ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((contact, idx) => {
                    const firstname = contact.firstname
                      ? contact.firstname
                      : "N/A";
                    const lastname = contact.lastname
                      ? contact.lastname
                      : "N/A";
                    const fullname = firstname + " " + lastname;
                    return (
                      <tr>
                        <td>{(currentPage - 1) * pageSize + idx + 1}</td>
                        <td>{fullname}</td>
                        <td>{contact?.email ? contact.email : "N/A"}</td>
                        <td>
                          {contact.address
                            ? contact.address.substring(0, 25)
                            : "N/A"}
                        </td>
                        <td>{contact.language ? contact.language : "N/A"}</td>
                        <td>
                          {contact.content
                            ? contact.content.substring(0, 25)
                            : "N/A"}
                        </td>

                        <td>
                          {/* <button type="button" class="btn btn-primary d-inline" onClick={() => {setRegister(index)}}>Edit</button>yar */}

                          <Grid item xs={8}>
                            <Tooltip
                              title="View"
                              onClick={() => viewFunction(contact)}
                            >
                              <IconButton aria-label="view">
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Reply"
                              onClick={() => {
                                replyPageCaller(contact);
                              }}
                            >
                              <IconButton aria-label="view">
                                <ReplyIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </td>
                        <td style={{ paddingLeft: "1%" }}>
                          <button
                            type="button"
                            className={[
                              "btn",
                              contact.status === "pending"
                                ? "btn-warning"
                                : "btn-success",
                            ].join(" ")}
                            onClick={() => approval(idx)}
                            style={{ color: "white" }}
                          >
                            {contact.status ? contact.status : "approved"}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}

          {totalPage > 1 ? (
            <div style={{ fontSize: "20px" }}>
              <Pagination
                current={currentPage > totalPage ? 1 : currentPage}
                total={totalPage}
                onPageChange={setCurrentPage}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <ReactBootStrap.Spinner animation="border" variant="success" />
        </div>
      )}
    </div>
  );
};

UserTable.propTypes = {
  isLoading: Proptypes.bool.isRequired,
  all: Proptypes.array.isRequired,
  viewFunction: Proptypes.func.isRequired,
  approval: Proptypes.func.isRequired,
  setView: Proptypes.func.isRequired,
};

export default UserTable;
