import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3DBBA3"
    },
    secondary: {
      main: "#3DBBA3"
    }
  },
  typography: {
    fontFamily: "Roboto"
  },
  shape: {
    borderRadius: 0
  },
  props: {
    MuiButton: {
      variant: "contained",
      disableRipple: true,
      color: "primary"
    },
    MuiTextField: {
      variant: "outlined",
      InputLabelProps: {
        shrink: true
      }
    },
    MuiPaper: {
      elevation: 0
    }
  },
 
});
