import React, { useEffect } from "react";
import "./dashboard.scss";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';

export default function Userdrop({ userlogout, changePassword }) {
  const [userdetail, setUserdetail] = React.useState("");

  useEffect(() => {
    if (!userdetail) {
      var item = JSON.parse(localStorage.getItem("res"))?.data;
      setUserdetail(item);
    }
  });


const fullname = userdetail?.firstname?userdetail?.firstname:"N/A"+" "+userdetail?.lastname?userdetail?.lastname:"N/A";
  return (
    <div className="display-flex">
      <button
        className="btn dropdown-toggle mr-4"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{
          backgroundColor: "#3AB8A1",
          color: "white",
          fontSize: "20lslpx",
        }}
      >
        {userdetail?.username ? userdetail?.username : "N/A"}
      </button>

      <div className="dropdown-menu" style={{cursor:"default"}}>
        <p className="dropdown-item disabled" href="#">
          Hello, {fullname ? fullname : "N/A"}!<br />
          <span>Role: {userdetail?.role?.role}</span>
        </p>
        <a className="dropdown-item" href="#" onClick={changePassword}>
          <LockOutlinedIcon /> Reset Password
        </a>
        <div className="dropdown-divider disabled"></div>
        <a className="dropdown-item" href="#" onClick={userlogout}>
          <PowerSettingsNewOutlinedIcon /> Logout
        </a>
      </div>
    </div>
  );
}
