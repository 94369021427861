import * as Yup from "yup";

export const measureStrength = (e, setPasswordStrength) => {
  let score = 0;
  let regexPositive = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
  if (e.target.value.length >= 8)
    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(e.target.value)) {
        score += 1;
      }
    });
  switch (score) {
    case 0:
    case 1:
      setPasswordStrength("weak");
      break;
    case 2:
    case 3:
      setPasswordStrength("good");

      break;
    case 4:
    case 5:
      setPasswordStrength("strong");
      break;
    default:
      setPasswordStrength("");
  }
};

const phoneRegex = "^[0-9]*$";
const alphanumeric = "^[a-zA-Z0-9_]*$";
const uppercase = /(?=.*?[A-Z])/;
const lowercase = /(?=.*?[a-z])/;
const number = /(?=.*?[0-9])/;
const symbol = /(?=.*?[#?!@$%^&*-])/;

export const ValidationSchema = Yup.object().shape({
  Firstname: Yup.string()
    .min(0, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  Lastname: Yup.string()
    .min(0, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  Date: Yup.string(),

  Contact: Yup.string().matches(phoneRegex, "Contact number must be a number"),

  Username: Yup.string()
    .matches(alphanumeric, "Username should be alphanumeric")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  Role: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),

  Language: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),

  Address: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  Email: Yup.string().email("Invalid email").required("Required"),
  Password: Yup.string()
    .matches(uppercase, "Must contain atleast one uppercase")
    .matches(lowercase, "must contain atleast one lowercase")
    .matches(number, "Must contain number")
    .matches(symbol, "must contain atleast one special character")
    .min(8, "atleast 8 character long")
    .required("Required"),
  Confirmpassword: Yup.string()
    .oneOf([Yup.ref("Password"), null], "Passwords should match")
    .required("Required"),
});
