import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import URL from "../url";
import { ToastContainer, toast } from "react-toastify";
import SearchBar from "material-ui-search-bar";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import "./holiday.scss";


function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://material-ui.com/">
          Edfluent
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }


const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
    },
    error: {
        color: "#c62121",
        padding: "10px",
        backgroundColor: "#ffe5e7",
        border: "1px solid #f5c6cb",
        width: "100%",
        borderRadius: "5px",
        marginTop: "15px",
    },
    errorSvg: {
        fontSize: "1rem",
        marginRight: "7px",
    },
    errorSpan: {
        fontSize: "14px",
    },
}));
export default function Holiday() {
    const [holidays, setHolidays] = useState([{ }]);
    const [mode, setMode] = useState("add");
    const [description, setDescription]= useState("");
    const [date, setDate] = useState("");
    const [name, setName] = useState("");
    const classes = useStyles();

    const deleteHolidayHandler = (id) => {
        //`http://localhost:3030/api/deleteHoliday/${id}`
        //`http://localhost:3030/api/deleteHoliday/${id}`
        //URL(`deleteHoliday/${id}`)
        axios.delete(URL(`deleteHoliday/${id}`)).then(()=>{
            fetchHolidays();
        }).catch(e=>console.log(e));
        
        /* setHolidays((p) => {
            return p.filter((obj, index) => {
                return obj.date !== date;
            });
        }); */

    };

    const editHandler = (date) => {
        setMode("edit");
        const obj = [...holidays].find((item) => item.date === date);
        if (obj) {
            setName(obj.name);
            setDescription(obj.description);
            setDate(obj.date);
        }
    };

    const confirmEdit = async () => {
        const clone = [...holidays];
        const ind = clone.findIndex((item) => item.date === date);
        if (ind !== -1) {
            const obj = clone[ind];
            obj.name = name;
            obj.description = description;
            clone[ind] = obj;
            //fetch edit api
            console.log("Edited:",obj);
            //"http://localhost:3030/api/holiday/edit"
            //"http://localhost:3030/api/holiday/edit"
            await axios.post(URL("holiday/edit"), obj).then(res=>console.log(res)).catch(e=>console.log(e));
            setHolidays(clone);
            setMode("add");
        }
        setDate("");
        setName("");
        setDescription("");
        fetchHolidays();
    };

    const addHoliday = async () => {
        if (date && name && localStorage.getItem("token")) {
            //console.log([...holidays].filter((item) => item.date === date).length === 0);
            if ([...holidays].filter((item) => item.date === date).length === 0) {
                //http://localhost:3030/api/holiday/new
                //"http://localhost:3030/api/holiday/new"
                //URL("holiday/new")
                await axios
                    .post(URL("holiday/new"), {
                        headers: {"token": `${localStorage.getItem("token")}`},
                            name: name,
                            date: date,
                            description: description,
                        })
                    .then((res) => {
                        //// debugger;
                        if (res.status === 200) {
                            console.log("Successful");
                        } else {
                            console.log("Not Successful");
                        }
                    });
            }
            /* 
            if ([...holidays].filter((item) => item.date === date).length === 0) {
                setHolidays((p) => [...p, { name, date }]);
            } */
            setName("");
            setDescription("");
            setDate("");
            fetchHolidays();
        }
    };
    //"http://localhost:3030/api/Allholidays"
    //"http://localhost:3030/api/Allholidays"
    const fetchHolidays = () => axios
    .get(URL("Allholidays"))
    .then(res =>{ setHolidays(res?.data || [{ }] );console.log(res.data);})
    .catch(e => { console.log(e) });

    useEffect(() => {
        fetchHolidays();
    }, [axios]);

    const defaultValues = (title) => {
        return {
          variant: "outlined",
          margin: "normal",
          fullWidth: true,
          id: title,
          label: title,
          name: title,
        };
      };

    return (
        <div className="well mt-4 container-fluid">
                <ToastContainer
                position="top-right"
                autoClose={3500}  
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div className="row align-items-center">
            <div className="col-4">
            <h4>Holiday Form</h4>
            </div>
            <div className="col-4"></div>
            <div className="col-4"></div>
        </div>
        <div className="row my-3 align-items-center">
            <div className="col-4">
            <p className="mb-0">
                Show
                <select className="custom-select">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                </select>
                entries
            </p>
            </div>
            <div className="col-4"></div>
            <div className="col-md-4">
            <SearchBar
                // value={}
                // onChange={(value) => searchItem(value)}
            />
            </div>
        </div>
            {holidays.length > 0 && (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            {/* {Object.keys(holidays[0]).map((title, index) => {
                                return <td key={index}>{title}</td>;
                            })} */}
                            <th>#</th>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {holidays.map(({ _id, userId,name, date, description }, i) => (
                            <tr key={_id}>
                                <td>{i+1}</td>
                                <td>{name}</td>
                                <td>{date}</td>
                                <td>{description}</td>
                                <td>
                                    <button onClick={() => editHandler(date)} className="edit-btn">
                                    <IconButton aria-label="view">
                                    <EditIcon/>
                                    </IconButton>
                                    </button>
        
                                    <button className="delete-btn"
                                        onClick={() => {
                                            deleteHolidayHandler(_id);
                                        }}
                                    >
                                    <IconButton aria-label="view">
                                        <DeleteIcon/>
                                    </IconButton>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <br />
            <br />
            <div className="mt-4 container">
                {/* <div className="col-3"></div> */}
                <div className="paper">
                <Typography component="h1" variant="h5" align="left">
                    {/* Holiday Form */}
                </Typography>
                </div>
                <form className="form" >
                <Grid container alignItems="flex-start" spacing={1}>
                    <Grid item md={6} sm={6} xs={12}>
                        <TextField
                        {...defaultValues("Enter your name")}
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                    <div
                    style={{
                        color: "#000000#A",
                        fontSize: "1rem",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        lineHeight: " 1",
                    }}
                    >
                    {/* Date  */}
                    </div>
                    
                    <TextField
                    value={date}
                    onChange={(e) => {
                        setDate(new Date(e.target.value).toISOString().slice(0, 10));
                    }}
                    variant="outlined"
                    id="date"
                    label=""
                    type="date"
                    disabled={mode === "edit"}
                    className="w-100 mt-3"
                    />
                </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <TextField
                    {...defaultValues("Enter description")}
                    multiline={true}
                    rows={5}
                    className="w-100"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    />
                </Grid>

                {mode === "add" ? (
                    <button
                    style={{
                        background: "rgb(58, 184,161)",
                        color: "white",
                        padding: "8px 20px", 
                        margin: "24px 0px 16px", 
                        border: "0",  
                        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                        borderRadius: "5px",
                        textTransform: "capitalize"
                    }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="submit"
                        onClick={() => {
                            addHoliday();
                        }}
                    >
                        {mode}
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            confirmEdit();
                        }}
                    >
                        {mode}
                    </button>
                )}
                </form>
                {/* <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="enter name"
                /><br/><br/> */}
                {/* <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                    placeholder="enter description"
                /><br/><br/> */}
                {/* <input
                    value={date}
                    onChange={(e) => {
                        setDate(new Date(e.target.value).toISOString().slice(0, 10));
                    }}
                    type="date"
                    disabled={mode === "edit"}
                    placeholder="enter date"
                />
                <br />
                <br /> */}

                <Box mt={8}>
                <Copyright />
                </Box>
                </div>
            </div>
    );
}
