import React, { useState } from "react";
import URL from "../url";

import "./styles.scss";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import * as ReactBootStrap from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
axios.defaults.withCredentials = true; //for  HTTPOnly cookie to work

function Copyright() {
  return (
    <Typography variant="body2" className="text-light" align="center">
      {"Copyright © "}
      <Link color="inherit" href="">
        Edfluent
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  div: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    // margin: "-26px",
    backgroundColor: "#1db195",
    background:
      "linear-gradient(135deg,#0aa8bc,#26aeaf 24%,#54b99a 48%,#92c77e 75%,#9eca79)",
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 5px 15px 0 rgba(0,0,0,.1)",
    position: "relative",
    top: "50%",
    left: "50%",
    background: "#fff",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#f29d49",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: "#1DB195",
  },
  box: {
    position: "absolute",
    bottom: "20px",
    textAlign: "center",
    width: "100%",
  },
  p: {
    color: "fff",
  },
  error: {
    color: "#c62121",
    padding: "10px",
    backgroundColor: "#ffe5e7",
    border: "1px solid #f5c6cb",
    width: "100%",
    borderRadius: "5px",
    marginTop: "15px",
  },
  errorSvg: {
    fontSize: "1rem",
    marginRight: "7px",
  },
  errorSpan: {
    fontSize: "14px",
  },
}));

const Login = () => {
  const classes = useStyles();
  let history = useHistory();
  const [loginError, setLoginError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [checked, setChecked] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const onChecked = (e) => {
    e.preventDefault();
    setChecked(e.target.checked);
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("This field is required")
      .min(2, "must be of 8 character")
      .max(20, "too long")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      //changes
      var res = await axios.post(URL("login"), {
        email: values.email,
        password: values.password,
        remember: checked,
      });
      if (res.data?.error) {
        setLoginError(res.data.error);
        setIsLoading(false);
      }
      if (res.status === 200) {
        if (res.data) {
          //// debugger;
          localStorage.setItem(
            "data",
            JSON.stringify(res?.data.role.accessRoutes)
          );
          var now = new Date();
          if (checked)
            localStorage.setItem(
              "Login",
              JSON.stringify({
                rememberMe: true,
                loginTime: now.getTime() + 1000 * 60 * 60 * 24 * 30,
                loginDateTime: now,
                loginStatus: true,
              })
            );
          //8hr TTL
          else
            localStorage.setItem(
              "Login",
              JSON.stringify({
                rememberMe: false,
                loginTime: now.getTime(),
                loginDateTime: now,
                loginStatus: true,
              })
            );
          localStorage.setItem("role", res.data.role?.role);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("res", JSON.stringify(res));
          localStorage.setItem("userid", res?.data.id);
          localStorage.setItem("language", res?.data.language);
          history.push("/");
          setLoginError(null);
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <>
      {/* <div className="mt-4 container-fluid"> */}
      {/* <ToastContainer
          position="top-right"
          hideProgressBar={false}
          autoClose={4000}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={false}
        /> */}
      <div className={classes.div + " login-form"}>
        <div className={classes.paper + " paper"}>
          <Avatar src="" className={classes.avatar}></Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          {loginError ? (
            <Box className={classes.error}>
              <CancelIcon className={classes.errorSvg} />
              <span className={classes.errorSpan}>{loginError}</span>
            </Box>
          ) : (
            ""
          )}
          <form
            className={classes.form}
            noValidate
            method="post"
            onSubmit={formik.handleSubmit}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email" /* {credentials?credentials.email:"email"} */
              /* value={credentials?credentials.email:formik.values.email} */
              {...formik.getFieldProps("email")}
              error={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null
              }
              helperText={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : null
              }
            />
            <div className="position-relative">
              <TextField
                variant="outlined"
                margin="normal" /* 
                value={credentials?.password} */
                required
                fullWidth
                name="password"
                label="Password"
                type={passwordShown ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                {...formik.getFieldProps("password")}
                error={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : null
                }
                helperText={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : null
                }
              />
              {passwordShown ? (
                <VisibilityOutlinedIcon
                  onClick={togglePasswordVisiblity}
                  className="password-toggle"
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  onClick={togglePasswordVisiblity}
                  className="password-toggle"
                />
              )}
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={onChecked}
                  name="remember"
                  color="primary"
                />
              }
              label="Remember me"
            />

            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ReactBootStrap.Spinner animation="border" variant="success" />
              </div>
            ) : (
              ""
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              style={{ color: "white" }}
            >
              Log In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/emailconfirm" variant="body2">
                  <p>Forgot password?</p>
                </Link>
              </Grid>
              {/* <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
            </Grid>
          </form>
        </div>
        <Box className={classes.box}>
          <Copyright />
        </Box>
      </div>
      {/* </div> */}
    </>
  );
};

export default Login;
