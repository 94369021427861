import React from "react";
import { Paper, Button } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import Box from "@material-ui/core/Box";
import axios from "axios";
import * as ReactBootStrap from "react-bootstrap";
import URL from "../url";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  error: {
    color: "#c62121",
    padding: "10px",
    backgroundColor: "#ffe5e7",
    border: "1px solid #f5c6cb",
    width: "100%",
    borderRadius: "5px",
    marginTop: "15px",
  },
  info: {
    color: "#a6a6a6",
    padding: "10px",
    backgroundColor: "#f6f6f6",
    border: "1px solid #e6e6e6",
    width: "100%",
    borderRadius: "5px",
    marginTop: "15px",
  },
  errorSvg: {
    fontSize: "1rem",
    marginRight: "7px",
  },
  errorSpan: {
    fontSize: "14px",
  },
  infoSpan: {
    fontSize: "14px",
    color: "#666",
  },
}));

const ResetPage = () => {
  const classes = useStyles();
  const [email, setEmail] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const sendMail = async () => {
    setIsLoading(true);
    try {
      await axios
        .post(URL("request_reset"), {
          email: email,
        })
        .then((res) => {
          if (res.status === 200) {
            setStatus(
              "Your request for changing password has been send and you will receive an email once it is accepted"
            );
            setIsLoading(false);
          } else {
            setStatus("Something Went Wrong !!");
            setIsLoading(false);
          }
        });
    } catch (error) {
      setIsLoading(false);
      setStatus("Invalid Email !!");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="manage-account-section">
        <Paper>
          <div className="reset-password-bg"></div>
          <div className="reset-password-section">
            <div className="reset-password-content">
              <h4>Did you forget your password?</h4>
              <p className="small-text">
                Please enter your registered email address. We will send you an
                email with instructions on how to change your password.
              </p>
            </div>
            <div className="clearfix"></div>
            <div className="reset-password-form">
              {status ? (
                <Box
                  className={
                    status.includes("Invalid") ? classes.error : classes.info
                  }
                  style={{ marginBottom: "10px" }}
                >
                  {status.includes("Invalid") ? (
                    <CancelIcon className={classes.errorSvg} />
                  ) : (
                    <InfoIcon className={classes.errorSvg} />
                  )}
                  <span className={classes.infoSpan}>{status}</span>
                </Box>
              ) : (
                ""
              )}

              <h5>Recover Password</h5>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <label
                  style={{
                    color: "black",
                    float: "left",
                  }}
                >
                  Email address:
                </label>

                <input
                  placeholder="name@emailaccount.com"
                  required="true"
                  style={{
                    marginBottom: "10px",
                    border: "0px",
                    borderBottom: "1px solid gray",
                    width: "100%",
                    outline: "none",
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ReactBootStrap.Spinner
                      animation="border"
                      variant="success"
                    />
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="reset-btn"
                  style={{
                    display: "grid",
                  }}
                >
                  <Link
                    to="/"
                    className="return-link"
                    style={{
                      textAlign: "left",
                      margin: "23px 0",
                    }}
                  >
                    Return to Login Page
                  </Link>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#3DBBA3",
                      color: "#fff",
                      width: "fit-content",
                      margin: "auto",
                      textTransform: "capitalize",
                    }}
                    onClick={sendMail}
                  >
                    Send password change email
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default ResetPage;
