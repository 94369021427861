import React from "react";
import "./dashboard.scss";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import axios from "axios";
const FETCH_API = process.env.FETCH_API;


export default function NotificationDropdown({
  setNavCase,
  setNotificationRoute,
}) {
  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FETCH_API}/api2/getNotifications`,{headers:{"token":`${localStorage.getItem("token")}`}})
      .then((result) => setNotifications(result.data));
  }, []);

  return (
    <span className="notification">
      <a
        className="dropdown-toggle pl-md-3 position-relative"
        href="/"
        onClick={(e) => {
          e.preventDefault();
        }}
        id="bell"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span>
          <NotificationsOutlinedIcon />
        </span>
        <span className="badge secondary-badge notify-no rounded-circle">
          {notifications?.length || ""}
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-left mailbox animated bounceInDown">
        <ul className="list-style-none">
          <li>
            <div className="message-center notifications position-relative">
              <div className="w-100 d-flex align-items-center space-between px-4 py-3 border-bottom">
                <h6 className="notification-title">Notifications</h6>
                <a
                  href="/"
                  onClick={(e) => e.preventDefault()}
                  className="mb-0 font-12"
                >
                  Mark All As Read
                </a>
              </div>
              <div className="notification-list">
                {notifications?.map((notification) => (
                  <div
                    onClick={() => {
                      setNavCase("notification");
                      // debugger;
                      setNotificationRoute(notification.page_route);
                    }}
                  >
                    <a
                      href="/"
                      onClick={(e) => e.preventDefault()}
                      className="message-item d-flex align-items-start px-3 py-3"
                    >
                      <div id="new"></div>
                      <div className="icon-box">
                        <NotificationsOutlinedIcon />
                      </div>
                      <div className="w-75 d-inline-block v-middle pl-2">
                        <h6 className="notify">
                          {notification.user_id} has made changes to{" "}
                          {notification.page}
                        </h6>
                        {/* <h6 className="message-title">{notification.title}</h6>
                        <span className="font-13 text-nowrap d-block text-truncate">
                          {notification.body}
                        </span> */}
                        <span className="font-12 mb-0 text-nowrap d-block text-muted">
                          {notification.username}
                        </span>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </span>
  );
}
